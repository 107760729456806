<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/team/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">团队介绍</div>
        <div class="line"></div>
        <div class="title1">TEAM TO INTRODUCE</div>
      </div>
      <div class="bom">
        <div class="left-item">
          <div class="item" v-for="item in teamList1" :key="item.index">
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
            <img :src="item.img" class="img" />
          </div>
        </div>
        <div class="right-item">
          <div class="item" v-for="item in teamList2" :key="item.index">
            <img :src="item.img" class="img" />
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">核心优势</div>
        <div class="line"></div>
        <div class="title1">CORE STRENGTHS</div>
      </div>
      <div class="bom">
        <div class="item" v-for="(item, index) in youshiList" :key="index">
          <img :src="item.icon" class="icon" />
          <div class="title">{{ item.title }}</div>
          <!-- <div class="text">{{ item.text }}</div> -->
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">服务宗旨</div>
        <div class="line"></div>
        <div class="title1">SERVICE TENET</div>
      </div>
      <div class="bom">
        <div class="bom-box">
          <div class="item" v-for="(item, index) in purposeList1" :key="index">
            <div class="left">
              <img :src="item.img" class="img" />
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="bom-box">
          <div class="item" v-for="(item, index) in purposeList2" :key="index">
            <div class="left">
              <img :src="item.img" class="img" />
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
export default {
  metaInfo: {
    title: "水木清芬",
    meta: [
      {
        name: "Team",
        content: "师资团队",
      },
    ],
  },
  components: { floatBar },
  props: {},
  data() {
    return {
      teamList1: [
        {
          img: require("../../assets/img/team/sztd1.png"),
          title: "蒋隆国",
          text: "著名英语专家、翻译家、教育家和诗人。清华大学教授、曾任全国青少年英语口语活动评委会副主席、建国初英语研究生四才子之一、师从著名英语专家许国璋先生。蒋教授潜心著书，推出了一整套蒋隆国英语系列丛书，主编了大学英语四、六级和考研英语读物达五十余种。",
        },
        {
          img: require("../../assets/img/team/sztd3.png"),
          title: "余冲",
          text: "中国科学院物理研究生有多年初高中物理、数学家教的教学经验，所带学生在中高考都取得了优异的成绩。余老师善于用最简洁的语言深入浅出地讲解疑难知识点，所授物理课深受学生和家长欢迎。",
        },
        {
          img: require("../../assets/img/team/sztd5.png"),
          title: "王本明",
          text: "英语专业本科，资深英语编辑、主审，英语词汇记忆专家，英语学习辅导专家。先后从事英语翻译、英语编辑以及英语教育工作，学生双语报、英语学习辅导报等知名英语报刊以及全国优秀出版社特约作者。",
        },
      ],
      teamList2: [
        {
          img: require("../../assets/img/team/sztd2.png"),
          title: "彭树强",
          text: "中国全脑思维高级讲师；教育部“十三五”重点课题学术秘书；《“触目经心” 记忆法12天攻破初中单词》《 “触目经心”记忆法12天攻破高中单词》 《6天思维导图学语法》等书籍主编；15天倒背如流《雅思》五千单词； 4天倒背如流《道德经》；15天倒背如流《汉英对照汪国真80首诗选》参加CCTV《奋斗》、山东卫视、等媒体节目录制和《雅思》单词倒背如流展示活动。",
        },
        {
          img: require("../../assets/img/team/sztd4.png"),
          title: "郭大野",
          text: "2008年奥运志愿者口语培训讲师、北京新国人教育集团、教学教研兼教学副校长国家汉办孔子学院长城汉语中心对外汉语教研员兼北京语言大学对外汉语讲师北京师大励耘青少年学习中心 专家委员会委员兼高级培训讲师",
        },
        {
          img: require("../../assets/img/team/sztd6.png"),
          title: "刘太瑞",
          text: "原山东省特级数学教师，北京市人大附中特聘教师（2008年9月-2014年6月）。连续多次参与北京市高考命题，多次参与高考阅卷。因材施教，没有学不会的学生，只有不会教的老师教学特色",
        },
      ],
      youshiList: [
        {
          index: 0,
          icon: require("../../assets/img/team/img-ys11.png"),
          title: "专业性强",
          text: "有一支年轻、有活力、有脑洞的团队，创意灵感源源不绝",
        },
        {
          index: 1,
          icon: require("../../assets/img/team/img-ys12.png"),
          title: "资源丰富",
          text: "拥有一网双微等多个新媒体平台，更有微信红人朋友圈，宣传渠道丰富。",
        },
        {
          index: 2,
          icon: require("../../assets/img/team/img-ys13.png"),
          title: "团队协作性强",
          text: "公司团队以90后为主，团队成员团结友爱、协作力强",
        },
        {
          index: 3,
          icon: require("../../assets/img/team/img-ys14.png"),
          title: "执行力强",
          text: "公司遵循“高效、快速、精准”的服务宗旨，高效快速的执行客户方案",
        },
      ],
      purposeList1: [
        {
          index: 0,
          img: require("../../assets/img/team/purpose-1.png"),
          title: "快速",
          text: "及时快速地为客户提供优质的互联网服务",
        },
        {
          index: 1,
          img: require("../../assets/img/team/purpose-2.png"),
          title: "有效",
          text: "为客户提供有效的服务提案和服务策划",
        },
        {
          index: 2,
          img: require("../../assets/img/team/purpose-3.png"),
          title: "创新",
          text: "不断创新，提升企业综合服务能力",
        },
      ],
      purposeList2: [
        {
          index: 3,
          img: require("../../assets/img/team/purpose-4.png"),
          title: "诚信",
          text: "按时保质保量为客户提供互联网服务",
        },
        {
          index: 4,
          img: require("../../assets/img/team/purpose-5.png"),
          title: "精益求精",
          text: "不断提升员工能力，将服务做到最好",
        },
        {
          index: 5,
          img: require("../../assets/img/team/purpose-6.png"),
          title: "专业",
          text: "有较强的专业理论知识和运营实战经验",
        },
      ],
      curIndex1: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content1 {
  margin-top: 70px;
  .bom {
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    .left-item {
      flex: 1;
      .item {
        height: 1000px;
        border-radius: 10px;
        text-align: center;
        .img {
          width: 400px;
          // height: 327px;
        }
        .textbox {
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              color: #000014;
              font-size: 16px;
              text-align: left;
            }
          }
        }
      }
    }
    .right-item {
      flex: 1;
      .item {
        height: 1000px;
        border-radius: 10px;
        text-align: center;
        .img {
          width: 400px;
          // height: 327px;
        }
        .textbox {
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              color: #000014;
              font-size: 16px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
.content2 {
  width: 100%;
  height: 470px;
  background: #f0f5fa;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  padding-top: 30px;
  .bom {
    height: 380px;
    margin: 0 auto;
    margin-left: 350px;
    margin-right: 350px;
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    .item {
      width: 216px;
      height: 250px;
      position: relative;
      text-align: center;
      .icon {
        width: 100px;
        height: 100px;
      }
      .title {
        font-size: 20px;
        margin-top: 40px;
        color: #000028;
        font-weight: 800;
      }
      .text {
        font-size: 16px;
        margin-top: 18px;
        color: #000014;
        text-align: left;
      }
    }
  }
}
.content3 {
  height: 483px;
  padding-top: 70px;
  width: 100%;
  .bom {
    margin: 0 auto;
    margin-top: 60px;
    .bom-box {
      display: flex;
      justify-content: space-around;
      margin-left: 350px;
      margin-right: 350px;
      .item {
        width: 250px;
        height: 76px;
        border-radius: 10px;
        margin-bottom: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 60px;
          height: 60px;
          position: relative;
          top: 15px;
        }
        .right {
          margin-left: 18px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000028;
          }
          .line {
            width: 44px;
            height: 2px;
            background: #ef8200;
            margin-top: 6px;
            margin-bottom: 12px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
</style>